import React from 'react';
import {Badge, Inline, Stack, Text} from "@amboss/design-system";
import {useTranslation} from "react-i18next";
import {ParsStatus} from "../../interfaces/parsStatus";
import {getColorFromParsStatus, getLabelFromStatus} from "../../utils";
import Tooltip from "./Tooltip";
import {CreditType} from "../../interfaces/credit";
import {useAppSelector} from "../../hooks/redux";
import {creditsTypeSelector} from "../../store/credits/creditsSlice";
import {config} from "../../config";

declare type ItemNejmProps = {
    itemValue: number;
    parsStatus?: ParsStatus
    parsResponse?: any
}

const ItemNejm = ({itemValue, parsStatus, parsResponse}: ItemNejmProps) => {
    const {t} = useTranslation();
    const creditType = useAppSelector(creditsTypeSelector);
    const getTextForTooltip = () => {
        if (parsStatus === config.parsStatus.NOT_SUBMITTED) {
            return t('credits.tabs.tab3.tooltip.notSubmitted')
        }
        if (parsStatus === config.parsStatus.REJECTED) {
            return parsResponse ? `${ parsResponse.errorMessage} (Error ${parsResponse.errorCode})` : t('credits.tabs.tab3.tooltip.rejected')
        }
        return ""
    }

    const idToolTip = Math.random();

    return (
        <Stack space={'xs'}>
            <Inline>
                <Text>
                    {t('credits.tabs.tab1.listItem.itemValue', {count: itemValue})}
                </Text>
            </Inline>
            <Inline>
                <Text color={'tertiary'}>
                    {config.boardTitles[creditType]}
                </Text>
                {parsStatus &&
                    <>
                        <span data-tip data-for={idToolTip.toString()}>
                        {parsStatus !== config.parsStatus.ACCEPTED &&
                            <Tooltip text={`${t('credits.tabs.tab3.tooltip.general')} ${getTextForTooltip()}`} id={idToolTip.toString()}/>
                        }
                            {creditType !== CreditType.ABFM && <Badge color={getColorFromParsStatus(parsStatus)}
                                                                      text={getLabelFromStatus(parsStatus)}/>}
                        </span>
                    </>
                }
            </Inline>
        </Stack>
    );
};

export default ItemNejm;
