type Config = {
    [key: string]: any;
};
export const config: Config = {
    creditsType: {
        IPC: 'ipc',
        ABIM: 'abim',
        ABP: 'abp',
        ABFM: 'abfm',
    },
    boardTitles: {
        abim: 'Internal Medicine Board Review',
        abp: 'Pediatrics Board Review',
        abfm: 'Family Medicine Board Review',
    },
    answersNeeded: 6,
    creditsLinks: {
        ipc: 'https://support.amboss.com/hc/en-us/articles/23670009661713-Internet-Point-of-Care-CME',
        abfm: 'https://support.amboss.com/hc/en-us/articles/23670243369617-NEJM-Knowledge-Family-Medicine-Board-Review-CME',
        abim: 'https://support.amboss.com/hc/en-us/articles/23670195542161-NEJM-Knowledge-Internal-Medicine-Board-Review-CME',
        abp: 'https://support.amboss.com/hc/en-us/articles/23670251202577-NEJM-Knowledge-Pediatrics-Board-Review-CME',
    },
    creditStatus:{
        TO_REVIEW: 'to-review',
        REVIEWED: 'reviewed',
        CLAIMED: 'claimed',
    },
    analytics: {
        events: {
            CME_PAGE_OPENED: 'cme_page_opened',
            CME_ACCOUNT_SETUP_SAVED: 'cme_account_setup_saved',
            CME_REVIEW_CREDITS_CLICKED: 'cme_review_credits_clicked',
            CME_CREDITS_TOGGLED: 'cme_credits_toggled',
            CME_CREDITS_REVIEW_SUBMITTED: "cme_credits_review_submitted",
            CME_CREDITS_CLAIM_SUBMITTED: "cme_credits_claim_submitted",
            CME_PDF_DOWNLOADED: "cme_pdf_downloaded",
            CME_CREDITS_CLAIM_SUCCESSFUL: "cme_credits_claim_successful",
            CME_CREDITS_CLAIM_FAILED: "cme_credits_claim_failed",
            CME_CREDITS_CLAIM_REJECTED: "cme_credits_claim_rejected",
        },
        activities: {
            ipc: 'internet_searching_learning',
            abfm: 'nejm_abfm',
            abp: 'nejm_abp',
            abim: 'nejm_abim',
        }
    },
    api: {
        fileName: (creditId: string) => `${creditId}_certification.pdf`,
        endpoints: {
            download: (creditId: string) => `claimed/${creditId}/certification`,
        }
    },
    const: {
        REVIEW: 'review',
        REVIEWS: 'reviews',
        QUESTION: 'question',
        CREDIT_ID: 'creditId',
        CREDIT_IDS: 'creditIds',
        ANSWERS: 'answers',
        ANSWER: 'answer',
    },
    parsStatus: {
        ACCEPTED: 'Accepted',
        REJECTED: 'Rejected',
        NOT_SUBMITTED: 'not-submitted'
    },
    parsRecoverableErrorCodes: ["621", "622", "623", "624", "661", "664", "665", "718"],
    customEvents: {
        REFRESH_REVIEW: 'refresh-review',
    },
    external: {
        links: {
            PRIVACY_POLICY: 'https://www.amboss.com/us/privacy',
            PRIVACY_POLICY_MOBILE: 'https://www.amboss.com/us/privacy?no-header',
            OTHER_TYPES: 'https://forms.gle/4D5nDPTErF8cog5GA',
            LOGOUT: 'https://next.amboss.com/us'
        }
    },
    socket: {
        sort: {
            UPDATED_AT: 'updatedAt',
            SEARCH_DATE: 'searchDate',
        },
        NAMESPACE: 'cme',
        // https://longwavestudio.atlassian.net/wiki/spaces/CME/pages/46039070/Actions
        actions: {
            SAVE_PROFILE: 'save-profile', // https://longwavestudio.atlassian.net/wiki/spaces/CME/pages/46039298/Save+Profile
            GET_PROFILE: 'get-profile', // https://longwavestudio.atlassian.net/wiki/spaces/CME/pages/46039265/Get+Profile
            REVIEW_CREDIT: 'review-credit', // https://longwavestudio.atlassian.net/wiki/spaces/CME/pages/46039105/Review+Credit
            GET_CREDITS: 'get-credits', // https://longwavestudio.atlassian.net/wiki/spaces/CME/pages/46039118/Get+Credits
            CLAIM_CREDIT: 'claim-credit', // https://longwavestudio.atlassian.net/wiki/spaces/CME/pages/46694401/Claim+Credit
            GET_TOTALS: 'get-totals',
            PARS_RESUBMIT: 'pars-resubmit'
        },
        events: {
            CONNECTED: 'connected', // https://longwavestudio.atlassian.net/wiki/spaces/CME/pages/46039322/Connected+Event
            CONNECT_ERROR: 'connect_error',
            CLOSED: 'closed',
            ERROR: 'error'
        },
        tokens: {
            TO_REVIEW: 'to-review',
            CLAIMED: 'claimed',
            REVIEWED: 'reviewed',
        }
    },
    tabs: {
        AVAILABLE: "available",
        CLAIMED: 'claimed',
        REVIEWED: 'reviewed',
    },

    forms: {
        personalInformation: {
            NAME: 'name',
            LAST_NAME: 'lastName',
            EMAIL: 'email',
            BIRTH_DATE: 'birthDate',
            GRADUATION_DATE: 'graduationDate',
            CLINICAL_ROLE: 'clinicalRole',
            CLINICAL_ROLE_VALUE: 'clinicalRoleValue',
            ACCEPT_TERMS: 'acceptTerms',
            CLINICAL_ROLES: {
                PHYSICIAN: 'physician',
                PHYSICIAN_ASSOCIATE: 'physician_associate',
                NURSE: 'nurse',
                NURSE_PRACTITIONER: 'nurse_practitioner',
                PHARMACIST: 'pharmacist',
                OTHER: 'other'
            }
        },
        specialtyState: {
            BOARDS: 'boards',
            USER_CODE: 'userCode',
            BOARD_ID: 'boardId',
            CURRENT_BOARD: 'currentBoard',
            types:  {
                SPECIALTY: 'specialty',
                STATE: 'state'
            }
        },
        cpd: {
            ASSOCIATION: 'association',
            types: {
                AMA: 'ama',
                AHPRA: 'ahpra'
            }
        },
        review: {
            questions: [
                {
                    id: 1,
                    label: 'credits.tabs.tab1.review.questions.q1',
                    answers: [
                        {id: 1, label: 'credits.tabs.tab1.review.questions.q1a1'},
                        {id: 2, label: 'credits.tabs.tab1.review.questions.q1a2'},
                        {id: 3, label: 'credits.tabs.tab1.review.questions.q1a3'},
                        {id: 4, label: 'credits.tabs.tab1.review.questions.q1a4'},
                        {id: 5, label: 'credits.tabs.tab1.review.questions.q1a5'},
                    ]
                },
                {
                    id: 2,
                    label: 'credits.tabs.tab1.review.questions.q2',
                    answers: [
                        {id: 1, label: 'credits.tabs.tab1.review.questions.q2a1'},
                        {id: 2, label: 'credits.tabs.tab1.review.questions.q2a2'},
                        {id: 3, label: 'credits.tabs.tab1.review.questions.q2a3'},
                        {id: 4, label: 'credits.tabs.tab1.review.questions.q2a4'},
                    ]
                },
            ]
        },
        claim: {
            questions: [
                {
                    id: 1,
                    label: 'credits.tabs.tab2.claim.modal.questions.q1',
                    hide: [],
                },
                {
                    id: 2,
                    label: 'credits.tabs.tab2.claim.modal.questions.q2',
                    hide: ['abim', 'abfm', 'abp']
                },
                {
                    id: 3,
                    label: 'credits.tabs.tab2.claim.modal.questions.q3',
                    hide:[]
                },
                {
                    id: 4,
                    label: 'credits.tabs.tab2.claim.modal.questions.q4',
                    hide:[]
                }
            ],
            answers: [
                {
                    id: 1,
                    label: 'credits.tabs.tab2.claim.modal.answers.a1',
                },
                {
                    id: 2,
                    label: 'credits.tabs.tab2.claim.modal.answers.a2',
                },
                {
                    id: 3,
                    label: 'credits.tabs.tab2.claim.modal.answers.a3',
                },
                {
                    id: 4,
                    label: 'credits.tabs.tab2.claim.modal.answers.a4',
                },
            ]
        }
    },
}
