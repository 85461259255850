import {combineReducers} from "@reduxjs/toolkit";
import profileReducer from "./profile/profileSlice";
import editProfileReducer from "./editProfile/editProfileSlice";
import bottomModalReducer from "./bottomModal/bottomModalSlice";
import creditsReducer from "./credits/creditsSlice";
import totalCreditsReducer from "./totalCredits/totalCreditsSlice";
import loadingReducer from "./loading/loadingSlice";

export const reducers = combineReducers<any>({
    profile: profileReducer,
    editProfile: editProfileReducer,
    bottomModal: bottomModalReducer,
    credits: creditsReducer,
    totalCredits: totalCreditsReducer,
    loading: loadingReducer,
});

export type RootState = ReturnType<typeof reducers>;
