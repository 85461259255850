import React from 'react';
import {H4, Inline, Link, Stack} from "@amboss/design-system";
import {useTranslation} from "react-i18next";
import PersonalInformationForm from "../../../forms/PersonalInformationForm";
import {config} from "../../../../config";
import {isWebview} from '../../../../utils';


const PersonalInformation = () => {
    const {t} = useTranslation()

    return (
        <Stack space={'l'}>
            <Inline alignItems={'spaceBetween'}>
                <H4>{t('accountSetup.sections.personalInformation.title')}</H4>
                <Link href={isWebview() ? config.external.links.PRIVACY_POLICY_MOBILE : config.external.links.PRIVACY_POLICY}
                      target={"_blank"}
                      rel={"noopener noreferrer"}>{t('accountSetup.sections.personalInformation.privacyPolicy')}</Link>
            </Inline>
            <PersonalInformationForm/>
        </Stack>
    );
};

export default PersonalInformation;
