import {createContext, useEffect, useRef, useState} from "react";
import socketIOClient from 'socket.io-client';
import {config} from "../config";
import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";
import * as Sentry from "@sentry/react";

export const SocketContext = createContext({socket: null, socketReady: false, socketError: false});

const SocketProvider = ({children}) => {
    const [searchParams] = useSearchParams();
    const accessToken = searchParams.get("token")
    const navigate = useNavigate();

    const [socketReady, setSocketReady] = useState(false)
    const [socketError, setSocketError] = useState(false)

    const client = socketIOClient((window.be_url || process.env.REACT_APP_BASE_URL) + config.socket.NAMESPACE, {
        transports: ['websocket'],
        query: {
            accessToken: accessToken,
            version: 1, // optional chat major version supported by clients;
            platform: 'web', // optional string defining client platform;
            appVersion: 1, // optional client app major version;
            lang: 'it' // optional default on server IT
        },
    })

    const socket = useRef(client)

    useEffect(() => {
        if (!accessToken) {
            setSocketError(true)
            socket.current.removeAllListeners();
            socket.current.close();
        }
    }, [accessToken])

    useEffect(() => {
        socket.current.on(config.socket.events.CONNECTED, (res) => {
            setSocketReady(true)
            setSocketError(false)

            if (window.sentry_dsn){
                Sentry.configureScope((scope) => {
                    scope.setUser({
                      username: res.user.email,
                      id: res.user.xid,
                    });
                  });
            }
        });

        socket.current.on(config.socket.events.CLOSED, () => {
            setSocketReady(false)
        })

        socket.current.on(config.socket.events.ERROR, (msg) => {
            setSocketError(true)
        });

        socket.current.on(config.socket.events.CONNECT_ERROR, (msg) => {
            setSocketError(true)
            socket.current.removeAllListeners();
            socket.current.close();
            navigate({pathname: '/', search: createSearchParams({
                    token: accessToken
                }).toString()}, {replace: true});
        });
    }, []);

    return (
        <SocketContext.Provider
            value={{
                socket: socket.current,
                socketReady: socketReady,
                socketError: socketError
            }}>{children}</SocketContext.Provider>
    );
};

export default SocketProvider;
