import {BoardAddProps} from "./types";
import {ClaimCreditsOptions} from "../interfaces/claimCreditsOptions";
import {ReviewForm} from "../interfaces/reviewForm";
import {hasAtLeastOneChecked} from "../utils";
import {AccountSetupFields} from "../interfaces/accountSetupFields";

export const validateBoardAdd = ({id, userCode}: BoardAddProps) => {
    if (id !== '' && userCode?.length > 0)
        return false
    else return true
}

export const validateClaimCredits = ({creditIds, rating}: ClaimCreditsOptions) => {
    if (creditIds.length <= 0) return false
    if (rating.note?.length === 0) return false
    let atLeastOneUnanswered = false
    rating.qa.forEach(item => {
        if (item.answer === null) atLeastOneUnanswered = true
    })
    if (atLeastOneUnanswered) return false
    return true
}

export const validateCreditReview = (values: ReviewForm) => {
    const {creditId, review} = values
    if (!creditId) return {creditId: 'required'}
    let checkboxCompleted = 0
    review.forEach(question => {
        if (hasAtLeastOneChecked(question.answers))
            checkboxCompleted += 1
    })

    if (checkboxCompleted === review.length) return {}

    return {review: 'required'};
};

export const validateProfileSubmit = (values: AccountSetupFields) => {
    let errors = {}
    if (!values?.name) {
        errors = {...errors, name: 1}
    }
    if (!values?.lastName) {
        errors = {...errors, lastName: 1}
    }
    if (!values?.graduationDate) {
        errors = {...errors, graduationDate: 1}
    }
    if (!values?.birthDate) {
        errors = {...errors, birthDate: 1}
    }
    if (!values?.email) {
        errors = {...errors, email: 1}
    }
    if (values?.clinicalRoleValue === '' || !values.clinicalRoleValue) {
        errors = {...errors, clinicalRoleValue: 1}
    }
    return errors
}
