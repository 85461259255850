import React from 'react';
import {Badge, Inline, Stack, Text} from "@amboss/design-system";
import {Trans, useTranslation} from "react-i18next";
import {ParsStatus} from "../../interfaces/parsStatus";
import {getColorFromParsStatus, getLabelFromStatus} from "../../utils";
import Tooltip from "./Tooltip";
import {CreditAssociation, CreditType} from "../../interfaces/credit";
import {useAppSelector} from "../../hooks/redux";
import {creditsTypeSelector} from "../../store/credits/creditsSlice";
import {config} from "../../config";

declare type ItemArticleProps = {
    association?: string,
    parsStatus?: ParsStatus
    parsResponse?: any
}

const ClaimedItem = ({association, parsStatus, parsResponse}: ItemArticleProps) => {
    const {t} = useTranslation();
    const creditType = useAppSelector(creditsTypeSelector);

    const getTextForTooltip = () => {
        switch(parsStatus) {
            case config.parsStatus.NOT_SUBMITTED:
                return t('credits.tabs.tab3.tooltip.notSubmitted');
            case config.parsStatus.REJECTED:
                return parsResponse
                ? t('credits.tabs.tab3.tooltip.rejected', {context: 'specific', message: parsResponse.errorMessage, code: parsResponse.errorCode})
                : t('credits.tabs.tab3.tooltip.rejected',  {context: 'generic'})
            default:
                return '';
        }
    }

    const idToolTip = Math.random();

    return (
        <Stack space={'xs'}>
            <Text>
                <Trans i18nKey={`credits.tabs.tab3.listItem.${association}`} components={{text: association === CreditAssociation.AMA ? <em /> : <span />}}>
                    {association}
                </Trans>
            </Text>
            <Inline>
                <Text color={'tertiary'}>
                    {t(`credits.tabs.tab3.listItem.ipc`)}
                </Text>

                {parsStatus &&
                    <>
                        <span data-tip data-for={idToolTip.toString()}>
                        {parsStatus !== config.parsStatus.ACCEPTED &&
                            <Tooltip text={`${t('credits.tabs.tab3.tooltip.general')} ${getTextForTooltip()}`}
                                     id={idToolTip.toString()}/>
                        }
                            {creditType !== CreditType.ABFM && <>
                                    <Badge color={getColorFromParsStatus(parsStatus)}
                                           text={getLabelFromStatus(parsStatus)}/>
                                </>
                            }
                        </span>
                    </>
                }
            </Inline>
        </Stack>
    );
};

export default ClaimedItem;
