import { FormEvent } from "react";
import { Button, Inline, Stack, Text } from "@amboss/design-system";
import CheckboxInput from "../inputs/CheckboxInput";
import { Form, Formik } from "formik";
import { config } from "../../config";
import { useTranslation } from "react-i18next";
import { validateCreditReview } from "../../validators/validators";
import { useModal } from "../../hooks/useModal";
import { ReviewModel } from "../../models/ReviewModel";
import { Credit } from "../../interfaces/credit";
import { useSocketEmit } from "../../hooks/useSocketEmit";
import { ReviewForm } from "../../interfaces/reviewForm";
import useCreditsPaginate from "../../hooks/useCreditsPaginate";
import { trackEvent } from "../../analytics";
import useProfile from "../../hooks/useProfile";
import {creditsTypeSelector, setActiveTab} from "../../store/credits/creditsSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";

declare type ReviewModalProps = {
  data: Credit;
};

const ReviewModal = ({ data }: ReviewModalProps) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const initialValues = ReviewModel(data.id) as any;
  const { reviewCredit } = useSocketEmit();
  const { refreshCredits } = useCreditsPaginate(config.tabs.AVAILABLE);
  const { profile } = useProfile();
  const dispatch = useAppDispatch();
  const creditType = useAppSelector(creditsTypeSelector);
  const handleSubmit = (values: ReviewForm) => {
    reviewCredit(values)
      .then(async () => {
        trackSubmittedWithAnswers(values);
        closeModal();
        await refreshCredits();
        dispatch(setActiveTab(1));
      })
      .catch(() => alert(t("credits.tabs.tab1.review.error")));
  };

  const trackSubmittedWithAnswers = (values: any) => {
    const cmeCreditsReviewSubmittedOptions = {
      xid: profile?.xid,
      cme_activity: config.analytics.activities[creditType],
      search_term: data.searchTerm,
      article_xid: data.articleXid?.join(","),
      question_1: t("credits.tabs.tab1.review.questions.q1"),
      answer_1_1: values.review[0].answers[0].value ? t("credits.tabs.tab1.review.questions.q1a1") : "",
      answer_1_2: values.review[0].answers[1].value ? t("credits.tabs.tab1.review.questions.q1a2") : "",
      answer_1_3: values.review[0].answers[2].value ? t("credits.tabs.tab1.review.questions.q1a3") : "",
      answer_1_4: values.review[0].answers[3].value ? t("credits.tabs.tab1.review.questions.q1a4") : "",
      answer_1_5: values.review[0].answers[4].value ? t("credits.tabs.tab1.review.questions.q1a5") : "",
      question_2: t("credits.tabs.tab1.review.questions.q2"),
      answer_2_1: values.review[1].answers[0].value ? t("credits.tabs.tab1.review.questions.q2a1") : "",
      answer_2_2: values.review[1].answers[1].value ? t("credits.tabs.tab1.review.questions.q2a2") : "",
      answer_2_3: values.review[1].answers[2].value ? t("credits.tabs.tab1.review.questions.q2a3") : "",
      answer_2_4: values.review[1].answers[3].value ? t("credits.tabs.tab1.review.questions.q2a4") : "",
    };
    trackEvent({
      event: config.analytics.events.CME_CREDITS_REVIEW_SUBMITTED,
      options: cmeCreditsReviewSubmittedOptions,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validateCreditReview}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      validateOnMount={true}
    >
      {({ values, setFieldValue, isValid }) => {
        const handleChange = (e: FormEvent<HTMLInputElement>, questionId: number, answerId: number) => {
          let reviewCloned = [...values.review];
          reviewCloned[questionId - 1].answers[answerId - 1].value = e.currentTarget.checked;
          setFieldValue(config.const.REVIEW, reviewCloned);
        };

        return (
          <Form>
            <section className="question-wrapper">
              <Stack space={"xl"}>
                {config.forms.review.questions.map((question: any, index: number) => {
                  return (
                    <div key={index}>
                      <Text color={"tertiary"}>{t(question.label).toString()}</Text>
                      {question.answers.map((answer: any, index: number) => {
                        return (
                          <CheckboxInput
                            key={index}
                            name={config.const.ANSWER}
                            label={t(answer.label).toString()}
                            onChange={(e:any) => handleChange(e, question.id, answer.id)}
                          />
                        );
                      })}
                    </div>
                  );
                })}
                <Inline alignItems={"right"} space={"s"}>
                  <Button variant={"secondary"} onClick={closeModal}>
                    {t("credits.modal.discard")}
                  </Button>
                  <Button onClick={function noRefCheck() {}} type={"submit"} disabled={!isValid}>
                    {t("credits.modal.add")}
                  </Button>
                </Inline>
              </Stack>
            </section>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ReviewModal;
