import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import Sections from "./bits/Sections";
import Heading from "./bits/Heading";
import PersonalInformation from "./bits/PersonalInformation";
import SpecialtyState from "./bits/SpecialtyState";
import CmeCpdCe from "./bits/CmeCpdCe";
import Footer from "./bits/Footer";
import { useSocketEmit } from "../../../hooks/useSocketEmit";
import { useAppSelector } from "../../../hooks/redux";
import useProfile from "../../../hooks/useProfile";
import { validateProfileSubmit } from "../../../validators/validators";
import { normalizeProfileParams } from "../../../utils";
import { setProfileLoaded } from "../../../store/profile/profileSlice";
import { AccountSetupFields } from "../../../interfaces/accountSetupFields";
import {config} from "../../../config";
import {Stack} from "@amboss/design-system";

const DEFAULT_VALUES: AccountSetupFields = {
  name: "",
  lastName: "",
  email: "",
  birthDate: "",
  graduationDate: "",
  boards: [],
  clinicalRole: "",
  clinicalRoleValue: "",
  association: "",
};

declare type ProfileEditProps = {
  callback: () => void;
  "data-testid"?: string;
};
const ProfileEdit = ({ callback }: ProfileEditProps) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState<AccountSetupFields>(DEFAULT_VALUES);
  const [loading, setLoading] = useState(true);
  const profileLoaded = useAppSelector((state) => state.profile.profileLoaded);

  const { refreshProfile, profile } = useProfile();
  const { saveProfile } = useSocketEmit();

  useEffect(() => {
    refreshProfile();
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [initialValues]);

  useEffect(() => {
    if (profileLoaded) {
      setInitialValues({
        name: profile?.name || "",
        lastName: profile?.lastName || "",
        email: profile?.email || "",
        birthDate: profile?.birthDate || "",
        graduationDate: profile?.graduationDate || "",
        boards: profile?.boards || [],
        clinicalRole: profile?.clinicalRole?.key || "",
        clinicalRoleValue: profile?.clinicalRole?.value || "",
        association: profile?.association || config.forms.cpd.types.AMA,
      });
    }
    return () => {
      setLoading(true);
    };
  }, [profileLoaded, profile]);

  const heading =
    !profile || !profile.name ? (
      <Heading text={t("accountSetup.sections.header.profileEdit") as string} />
    ) : (
      <></>
    );

  return (
    <>
      {!loading && (
        <Formik
          initialValues={initialValues}
          validate={validateProfileSubmit}
          validateOnMount={true}
          onSubmit={(values, actions) => {
            const updatedValues = normalizeProfileParams(values);
            saveProfile(updatedValues)
              .then(() => callback())
              .catch((err) => {
                setProfileLoaded(false);
                alert(
                  t(
                    "accountSetup.sections.personalInformation.form.submitError"
                  )
                );
              });
          }}
          onReset={callback}
        >
          <Form>
            <Sections>
              {heading}
              <Stack space={'xxl'}>
              <PersonalInformation />
              <SpecialtyState />
              <CmeCpdCe />
              <Footer />
              </Stack>
            </Sections>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default ProfileEdit;
