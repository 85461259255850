import {Review} from "./review";
import {ParsStatus} from "./parsStatus";
import {ParsResponse} from "./parsResponse";

export interface Credit {
    id: string,
    status: 'claimed' | 'reviewed' | 'to-review',
    type: CreditType,
    searchDate: string,
    value: number,
    searchTerm: string,
    articleTitles: string[],
    review: Review[],
    updatedAt: string,
    reportingYear?: string,
    originIds?: string[],
    externalIds?: string[],
    titles?: string[],
    parsStatus?: ParsStatus,
    startDate?: string,
    endDate?: string,
    claimDate?: string | null,
    articleXid?: string[],
    submittedBoards?: [],
    rating?: {}
    answered?: number,
    counted?: number,
    countedIds?: string[],
    remaining?: number,
    creditsCount?: number,
    questionIds?: number,
    parsResponse?: ParsResponse,
    association?: CreditAssociation
}

export enum CreditType {
    IPC = 'ipc',
    ABIM = 'abim',
    ABP = 'abp',
    ABFM = 'abfm',
}

export enum CreditAssociation {
    AMA = 'ama',
    AHPRA = 'ahpra'
}