import React from 'react';
import TabList from "../tabs/TabList";
import ItemDate from "../../../atoms/ItemDate";
import {Button, Inline} from "@amboss/design-system";
import {useTranslation} from "react-i18next";
import {Credit, CreditAssociation} from "../../../../interfaces/credit";
import ReviewModal from "../../../modals/ReviewModal";
import {config} from "../../../../config";
import {useModal} from "../../../../hooks/useModal";
import useProfile from "../../../../hooks/useProfile";
import {trackEvent} from "../../../../analytics";
import {Credits} from "../../../../interfaces/credits";
import {CreditType} from "../../../../interfaces/credit";
import moment from 'moment';
import {useAppSelector} from "../../../../hooks/redux";
import {creditsTypeSelector} from "../../../../store/credits/creditsSlice";
import IpcItemArticle from "../../../atoms/IpcItemArticle";
import ItemNejm from "../../../atoms/ItemNejm";

declare type CreditListProps = {
    credits: Credits
}

const CreditList = ({credits}: CreditListProps) => {
    const {t} = useTranslation()
    const {openModal, closeModal} = useModal()
    const {profile} = useProfile()
    const creditType = useAppSelector(creditsTypeSelector);

    const handleClick = (item: Credit) => {
        trackEvent({event: config.analytics.events.CME_REVIEW_CREDITS_CLICKED, options: {xid: profile?.xid, cme_activity: config.analytics.activities[creditType] }})

        openModal({
            onClose: closeModal,
            title: t('credits.tabs.tab1.review.modal.title', {searchTerm: item.searchTerm, value: item.value}),
            children: <ReviewModal data={item}/>
        })
    }

    const formattedDate = (item: any) =>{
        return creditType === CreditType.IPC ? moment(item.searchDate).format("MMMM D, YYYY") : `${moment(item.startDate).format("MMMM D, YYYY")} - ${moment(item.endDate).format("MMMM D, YYYY")}`
    }

    const getItemElement = (item:any) => {
        return creditType === CreditType.IPC ? <IpcItemArticle searchTerm={item.searchTerm} articleTitles={item.articleTitles}/> : <ItemNejm itemValue={item.value}/>
    }

    return (
        <>
            {credits?.items.map((item, i) => {
                return (
                    <TabList
                        key={item.id}
                        left={<ItemDate date={formattedDate(item)}/>}
                        center={getItemElement(item)}
                        right={<Inline alignItems={"right"} vAlignItems={"center"}>
                            <Button variant={'secondary'}
                                    onClick={() => handleClick(item)}>{ t('credits.tabs.tab1.listItem.button', {count: item.value, type: profile.association === CreditAssociation.AHPRA ? 'hour' : 'credit'})}</Button>
                    </Inline>}
                        isLastItem={(credits.items.length - 1) === i}/>
                )
            })}
        </>
    );
};

export default CreditList;
