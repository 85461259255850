import { Button, Card, CardBox, Link, Text } from "@amboss/design-system"
import LogoNejm from "../../../assets/images/LogoNejm.svg"
import {useTranslation} from "react-i18next";
import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {setCreditType} from "../../../store/credits/creditsSlice";
import {config} from "../../../config";
import { selectUser } from "../../../store/profile/profileSlice";
import {CreditAssociation, CreditType} from "../../../interfaces/credit";

declare type HomeSectionsCardProps = {
    type: string,
    credits: any,
}

export const HomeSectionsCard = ({type, credits}: HomeSectionsCardProps) =>{
    const {t} = useTranslation()
    const profile = useAppSelector(selectUser)
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const navigateToSection = () => {
        navigate({pathname: '/credits', search: `?${createSearchParams(searchParams)}`});
        dispatch(setCreditType(type));
    }

    return (
        <Card>
            <CardBox>
            <div className="home-card-header">
              <h3>{t(`home.cards.${type}.title`)} CME</h3>
              {type !== CreditType.IPC && <img src={LogoNejm} alt="" />}
            </div>
            <div style={{display:"flex", gap:"15px",justifyContent:"center", margin:"20px 0px"}}>
               {type === CreditType.IPC &&
                <div style={{width: "4.2rem", display:"flex", flexDirection:"column"}}>
                    <p style={{fontSize: "1.8rem", textAlign:"center", color:"#607585", margin:"5px"}}>{credits?.['to-review']}</p>
                    <Text align="center" size="xs" color="tertiary">{t(`home.labels.toReview`, 
                       {count: credits?.['to-review'], type: profile.association === CreditAssociation.AHPRA ? "HOUR": "CREDIT"})}
                    </Text>
                </div>}
                <div style={{width: "4.2rem", display:"flex", flexDirection:"column"}}>
                    <p style={{fontSize: "1.8rem", textAlign:"center", color:"#607585", margin:"5px"}}>{credits?.reviewed}</p>
                    <Text align="center" size="xs" color="tertiary">{t(`home.labels.toClaim`, 
                       {count: credits?.reviewed, type: profile.association === CreditAssociation.AHPRA && type === CreditType.IPC ?  "HOUR": "CREDIT"})}
                    </Text>
                </div>
            </div>
            <div className="home-card-text">
            <Text size="s">{t(`home.cards.${type}.description`, {context: profile.association})}</Text>
            </div>
            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:"5px"}}>
                <Link 
                    href={`${config.creditsLinks[type]}`}
                    color="accent"
                    size="s"
                    target="_blank"
                    rel="noopener noreferrer"
                 >
                    {t(`home.labels.learnMore`)}
                 </Link>
                <Button
                    onClick={navigateToSection}
                    size="s"
                    variant="primary"
                    >
                    {profile?.association === "ahpra" && type === "ipc" ? t(`home.labels.claimHours`) : t(`home.labels.claimCredits`)}
                </Button>
            </div>
            </CardBox>
        </Card>
    )
}