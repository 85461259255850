import {Box, Container, H2, Icon, Inline, Stack} from '@amboss/design-system';
import React, {CSSProperties, useEffect} from 'react';
import {useModal} from "../../hooks/useModal";
import {useMediaQuery} from "react-responsive";


const Modal = () => {
    const {modal, closeModal} = useModal()
    const isMobile = useMediaQuery({
        query: '(max-width: 500px)'
    })
    const rootStyle = isMobile ? mobileStyle : desktopOrTablet

    const escFunction = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            closeModal()
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [modal]);

    return (
        <>
            {modal?.open &&
                <div style={rootStyle}>
                    <Container elevation={4}>
                        <div style={{
                            overflowY: 'scroll',
                            height: isMobile ? '100vh' : 'auto',
                            maxHeight: isMobile ? '100%' : 700,
                            borderRadius: isMobile ? 0 : 12
                        }}>
                            <Box space={'xl'}>
                                <div onClick={modal.data.onClose}
                                     style={{position: 'absolute', top: 24, right: 24}}>
                                    <Icon
                                        hoverable={true} name={'x'}
                                        color={'tertiary'}/>
                                </div>
                                <Inline alignItems={"right"}>
                                    <div style={{height: 24}}/>
                                </Inline>
                                <Stack>
                                    <H2>{modal.data.title}</H2>
                                    <Stack space={'s'}>
                                        {modal.data.children}
                                    </Stack>
                                </Stack>
                            </Box>
                        </div>
                    </Container>
                </div>
            }
        </>
    );
};

const mobileStyle: CSSProperties = {
    zIndex: 9999,
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100vh',
}
const desktopOrTablet: CSSProperties = {
    zIndex: 9999,
    position: 'fixed',
    left: '50%',
    transform: 'translate(-50%, 0)',
}

export default Modal;
