import { useState } from "react";
import { Button, FormFieldGroup, Inline, Radio, Stack, Text, Textarea } from "@amboss/design-system";
import { config } from "../../config";
import { useTranslation } from "react-i18next";
import { useSocketEmit } from "../../hooks/useSocketEmit";
import { useModal } from "../../hooks/useModal";
import useBottomModal from "../../hooks/useBottomModal";
import { composeQaArrayFromConfig } from "../../utils";
import useLoading from "../../hooks/useLoading";
import useCreditsPaginate from "../../hooks/useCreditsPaginate";
import { trackEvent } from "../../analytics";
import useProfile from "../../hooks/useProfile";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {creditsTypeSelector, setActiveTab, tabSelector} from "../../store/credits/creditsSlice";
import { Qa } from "../../interfaces/bottomModal";
import { isNull } from "lodash";

declare type ClaimModalProps = {
  creditIds: string[];
};

declare type ratingType = {
  qa: Qa[];
  reason?: string;
  note?: string;
};

const ClaimModal = ({ creditIds }: ClaimModalProps) => {
  const { t } = useTranslation();
  const [qa, setQa] = useState(composeQaArrayFromConfig());
  const [note, setNote] = useState<string>("");
  const [reason, setReason] = useState<string>("");
  const { profile } = useProfile();
  const creditType = useAppSelector(creditsTypeSelector);
  const activeTab = useAppSelector(tabSelector);
  const { claimCredit } = useSocketEmit();
  const { closeModal } = useModal();
  const { resetBottomModalForm } = useBottomModal();
  const { setLoading } = useLoading();
  const { refreshCredits } = useCreditsPaginate(config.tabs.REVIEWED);
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    const rating: ratingType = { qa: qa };

    if (reason) {
      rating.reason = reason;
    }

    if (note) {
      rating.note = note;
    }

    setLoading(true);

    claimCredit({ creditIds, rating, type: creditType })
      .then((claimedCredit) => {
        trackSubmittedWithAnswers(rating, claimedCredit);
        trackParsResponse(claimedCredit);
        closeModal();
        resetBottomModalForm();
        refreshCredits().catch(e => console.log(e, 'refresh'));
        dispatch(setActiveTab(activeTab+1));
      })
      .catch((e) => {
        console.log(e)
        alert(t("credits.tabs.tab2.claim.error"))
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const trackSubmittedWithAnswers = (rating: any, claimedCredit: any) => {
    const cmeCreditsClaimSubmittedOptions = {
      xid: profile?.xid,
      cme_activity: config.analytics.activities[creditType],
      question_1: t("credits.tabs.tab2.claim.modal.questions.q1"),
      answer_1: !isNull(rating.qa[0].answer) ? t(config.forms.claim.answers[rating.qa[0].answer].label) : "",
      question_2: t("credits.tabs.tab2.claim.modal.questions.q2"),
      answer_2: !isNull(rating.qa[1].answer) ? t(config.forms.claim.answers[rating.qa[1].answer].label) : "",
      question_3: t("credits.tabs.tab2.claim.modal.questions.q3"),
      answer_3: !isNull(rating.qa[2].answer) ? t(config.forms.claim.answers[rating.qa[2].answer].label) : "",
      question_4: t("credits.tabs.tab2.claim.modal.questions.q4"),
      answer_4: !isNull(rating.qa[3].answer)? t(config.forms.claim.answers[rating.qa[3].answer].label) : "",
      reason_4: rating.reason ? rating.reason : "",
      question_5: t("credits.tabs.tab2.claim.modal.note"),
      answer_5: rating.note ? rating.note : "",
      credits_claimed_count: claimedCredit.value,
    };

    trackEvent({
      event: config.analytics.events.CME_CREDITS_CLAIM_SUBMITTED,
      options: cmeCreditsClaimSubmittedOptions,
    });
  };

  const trackParsResponse = (claimedCredit: any) => {


    if (claimedCredit.parsStatus === config.parsStatus.ACCEPTED) {
      trackEvent({
        event: config.analytics.events.CME_CREDITS_CLAIM_SUCCESSFUL,
        options: { xid: profile?.xid, credits_claimed_count: claimedCredit.value, cme_activity: config.analytics.activities[creditType]},
      });
    } else {
      const rejectedReason = claimedCredit.parsStatus;

      trackEvent({
        event: config.analytics.events.CME_CREDITS_CLAIM_FAILED,
        options: { xid: profile?.xid, reason: rejectedReason, credits_claimed_count: claimedCredit.value },
      });

      if(claimedCredit.parsStatus === config.parsStatus.REJECTED ) {
        const creditRejectedReason = claimedCredit.parsResponse?.errorMessage
        const creditRejectedCode = claimedCredit.parsResponse?.errorCode

        trackEvent({
          event: config.analytics.events.CME_CREDITS_CLAIM_REJECTED,
          options: { xid: profile?.xid, reason: creditRejectedReason, error_code: creditRejectedCode, status: claimedCredit.parsStatus, article_xid: claimedCredit.articleXid, cme_activity: config.analytics.activities[creditType] },
        });
      }
    }
  };

  // @ts-ignore
  return (
    <section className="question-wrapper">
      <Stack space={"xl"}>
        {config.forms.claim.questions.map((question: any, questionIndex: any) => (
            !question.hide.includes(creditType) && <Stack space={"s"} key={questionIndex}>
            <Text color={"tertiary"}>{t(question.label).toString()}</Text>

            <FormFieldGroup label={t("credits.tabs.tab2.claim.modal.radio.label").toString()}>
              {config.forms.claim.answers.map((answer: any, answerIndex: any) => (
                <Radio
                  key={answerIndex}
                  label={t(answer.label).toString()}
                  name={questionIndex.toString()}
                  onChange={() => {
                    const updatedQa = qa.map((item: any) => {
                      if (item.question === questionIndex) {
                        return { ...item, answer: answerIndex };
                      }
                      return item;
                    });
                    setQa(updatedQa);
                  }}
                />
              ))}
            </FormFieldGroup>
          </Stack>
        ))}
        {qa[3].answer !== 0 && qa[3].answer !== null && (
          <Stack space={"s"}>
            <Text color={"tertiary"}>{t("credits.tabs.tab2.claim.modal.q4reason")}</Text>
              <Textarea
                name={"reason"}
                value={""}
                maxLength={256}
                onChange={(e) => setReason(e.currentTarget.value)}
                hint={t("credits.tabs.tab2.claim.modal.textarea.hint").toString()}
              />
          </Stack>
        )}
        <Stack space={'s'}>
          <Text color={"tertiary"}>{t("credits.tabs.tab2.claim.modal.note")}</Text>
            <Textarea
              name={"note"}
              value={""}
              maxLength={256}
              onChange={(e) => setNote(e.currentTarget.value)}
              hint={t("credits.tabs.tab2.claim.modal.textarea.hint").toString()}
            />
        </Stack>
        <div
          style={{
            position: "fixed",
            bottom: 24,
            right: 32,
            backgroundColor: "#fff",
            borderRadius: 4,
            zIndex: 9999,
          }}
        >
          <Inline alignItems={"right"} space={"s"}>
            <Button onClick={handleSubmit} type={"submit"}>
              {t("credits.modal.submit")}
            </Button>
          </Inline>
        </div>
      </Stack>
    </section>
  );
};

export default ClaimModal;
