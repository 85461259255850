import React, {useEffect} from 'react';
import {Form, Formik} from 'formik';
import ReviewedList from "../lists/ReviewedList";
import EmptyPlaceholder from "../../../atoms/EmptyPlaceholder";
import {useTranslation} from "react-i18next";
import {config} from "../../../../config";
import useCreditsPaginate from "../../../../hooks/useCreditsPaginate";
import {Stack} from "@amboss/design-system";
import { useAppSelector } from '../../../../hooks/redux';
import { creditsTypeSelector } from '../../../../store/credits/creditsSlice';

const ReviewedTab = () => {
    const {t} = useTranslation()
    const {isLoading, credits, refreshCredits} = useCreditsPaginate(config.tabs.REVIEWED)

    useEffect(() => {
        refreshCredits();
    }, [])

    return (
        <>
            {!isLoading &&
                <Formik initialValues={{creditIds: [], rating: {}}}
                        onSubmit={(values, actions) => {
                        }}>
                    <Form>
                        <ReviewedList credits={credits}/>
                    </Form>
                </Formik>}
            {isLoading && <Stack space={'m'}>
                <EmptyPlaceholder header={t('loading.header')}
                                  subHeader={t('loading.subheader')}/>
            </Stack>}
        </>

    );
};

export default ReviewedTab;
