import {useEffect, useState} from 'react';
import {Button, Column, Columns, Inline, Input, Select} from "@amboss/design-system";
import {useAppSelector} from "../../../../hooks/redux";
import {selectAvailableBoards, selectUser} from "../../../../store/profile/profileSlice";
import {SelectOption} from "../../../../interfaces/availableBoardSelect";
import {useTranslation} from "react-i18next";
import {validateBoardAdd} from "../../../../validators/validators";
import {getTagFromBoard, removeItemFromArrayById} from "../../../../utils";
import Tag from "../../../atoms/Tag";
import useSelectBoard from "../../../../hooks/useSelectBoard";
import useAvailableBoardsForm from "../../../../hooks/useAvailableBoardsForm";
import {useFormikContext} from "formik";
import {config} from "../../../../config";

const SpecialtyStateForm = ({type}: any) => {
    const {t} = useTranslation()
    const availableBoardsRedux = useAppSelector(selectAvailableBoards)
    const profile = useAppSelector(selectUser)
    const [options, setOptions] = useState<SelectOption[]>([])
    const [selectedBoards, setSelectedBoards] = useState<any[]>([])
    const {selectedBoard, resetBoardFields, selectBoardId, selectUserCode} = useSelectBoard()
    const {availableBoards, syncAvailableBoards} = useAvailableBoardsForm()

    const selectLabel = t(`accountSetup.sections.specialtyState.select.labels.${type}`)
    const selectPlaceholder = t('accountSetup.sections.specialtyState.select.placeholder')
    const userCodeLabel = t(`accountSetup.sections.specialtyState.input.labels.${type}`)

    const {setFieldValue} = useFormikContext()

    useEffect(() => {
        if (profile?.boards) {
            setSelectedBoards(profile.boards)
        }
    }, [profile])

    useEffect(() => {
        if (selectedBoard?.id && selectedBoard?.userCode) {
            setFieldValue(config.forms.specialtyState.CURRENT_BOARD, {
                boardId: selectedBoard.id,
                userCode: selectedBoard.userCode
            }).catch(e => e)
        } else {
            setFieldValue(config.forms.specialtyState.CURRENT_BOARD, null).catch(e => e)
        }
    }, [selectedBoard])

    useEffect(() => {
        if (selectedBoards) {
            setFieldValue(config.forms.specialtyState.BOARDS, selectedBoards.map(selectedBoard => ({
                boardId: selectedBoard.id,
                userCode: selectedBoard.userCode
            }))).catch(e => e)
            syncAvailableBoards(selectedBoards)
        }
    }, [selectedBoards])

    const filterBoardsCallbackByType = (label: string) => {
        return type === config.forms.specialtyState.types.SPECIALTY
            ? label.includes("American" || "Australian")
            : !label.includes("American" || "Australian")
    }

    useEffect(() => {
        if (availableBoards && availableBoards.length > 0) {
            const boards = availableBoards.map(board => ({value: board.id, label: board.label})).filter((board: any) => filterBoardsCallbackByType(board.label))
            setOptions(boards)
        }
    }, [availableBoards, selectedBoards])

    const handleAdd = () => {
        setSelectedBoards(boards => ([...boards, selectedBoard]))
        resetBoardFields()
    }

    const handleRemoveTag = (id: string) => {
        const arr = removeItemFromArrayById(selectedBoards, id)
        syncAvailableBoards(selectedBoards)
        setSelectedBoards(arr)
    }

    const isSpecialty = (label: string) => {
        return type === config.forms.specialtyState.types.SPECIALTY && label.includes('American' || 'Australian')
    }
    const isState = (label: string) => {
        return type === config.forms.specialtyState.types.STATE && !label.includes('American' || 'Australian')
    }
    const specialityBoardTag = selectedBoards.map((selectedBoard, i) => {
            const tagText = getTagFromBoard(selectedBoard, availableBoardsRedux);
            const boardTag = <Tag key={i} text={tagText} onClick={() => handleRemoveTag(selectedBoard.id)}/>;
            return !isSpecialty(tagText) && !isState(tagText) ? '' : boardTag
        });

    return (
        <Columns gap={'m'} vAlignItems={"bottom"}>
            <Column size={[12, 4, 4]}>
                <Select
                    name={config.forms.specialtyState.BOARD_ID}
                    label={selectLabel}
                    maxHeight={200}
                    autoComplete={"true"}
                    onBlur={function noRefCheck() {
                    }}
                    onChange={function (e) {
                        selectBoardId(e.currentTarget.value)
                    }}
                    onFocus={function noRefCheck() {
                    }}
                    options={options}
                    placeholder={selectPlaceholder}
                    value={selectedBoard.id}
                />
            </Column>
            <Column size={[12, 6, 4]}>
                <Input
                    label={userCodeLabel}
                    name={config.forms.specialtyState.USER_CODE}
                    placeholder={t('accountSetup.sections.personalInformation.form.placeholders.text').toString()}
                    type={'text'}
                    value={selectedBoard.userCode || ''}
                    onChange={e => {
                        selectUserCode(e.currentTarget.value)
                    }}
                />
            </Column>
            <Column size={[12, 2, 4]}>
                <Button variant={'secondary'} leftIcon={'plus'} onClick={handleAdd}
                        disabled={validateBoardAdd(selectedBoard)}>add</Button>
            </Column>
            <Inline>
                {specialityBoardTag}
            </Inline>
        </Columns>
    );
};

export default SpecialtyStateForm;
