import React from 'react';
import {FormFieldGroup, H4, Radio, Stack} from "@amboss/design-system";
import {Trans, useTranslation} from "react-i18next";
import {useFormikContext} from 'formik';
import {AccountSetupFields} from '../../../../interfaces/accountSetupFields';
import {config} from "../../../../config";
import {CreditAssociation, CreditType} from "../../../../interfaces/credit";

interface LabelHintProps {
    type: string
}


const CmeCpdCe = () => {
    const {t} = useTranslation()
    const {values, setFieldValue} = useFormikContext<AccountSetupFields>();
    const radioLabel = t('accountSetup.sections.cmeCpdCe.label')
    const setAssociationValue = (e: any) => setFieldValue(config.forms.cpd.ASSOCIATION, e.currentTarget.value)

    const LabelHint = ({type}: LabelHintProps) =>  {
        return t(`accountSetup.sections.cmeCpdCe.hintLabels.${type}`)
            ? <Trans i18nKey={`accountSetup.sections.cmeCpdCe.hintLabels.${type}`} components={{text: type === CreditAssociation.AMA ? <em/>: <span/>}}>{type}</Trans>
            : <></>
    }

    return (
        <Stack space="m">
            <H4>{t('accountSetup.sections.cmeCpdCe.title')}</H4>
            <FormFieldGroup
                label={radioLabel}>
                {Object.values(config.forms.cpd.types).map((type: any) => {
                    return <React.Fragment key={type}>
                        <Radio
                            label={t(`accountSetup.sections.cmeCpdCe.optionLabels.${type}`) || config.forms.cpd.types[type]}
                            name={config.forms.cpd.ASSOCIATION}
                            checked={values.association === type}
                            value={type}
                            onChange={setAssociationValue}
                            labelHint={<LabelHint type={type} />}
                        />
                    </React.Fragment>
                })}

                {/*
                    negative margin and pLeft are used to obtain the visual effect since the radio button doesn't have a subLabel
                    https://www.figma.com/file/2HBeJ060ltblrCtsrrbq7t/Amboss-CME?node-id=74%3A1272&t=SVpTjnNUeZo8UkYK-4
                */}
            </FormFieldGroup>
        </Stack>
    );
};

export default CmeCpdCe;
