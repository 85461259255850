import React from 'react';
import {Input} from "@amboss/design-system";
import InputSpacer from "../atoms/InputSpacer";
import {useTranslation} from "react-i18next";

declare type TextInputProps = {
    name: string
    type: "text" | "password" | "number"
    label: string
    value: any
    hasHeader?: boolean
    labelHint?: string
    placeholder?: string
    hasError?: boolean
    errorMessages?: string[]
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextInput = ({
                       hasHeader = true,
                       name,
                       type,
                       value,
                       placeholder = 'accountSetup.sections.personalInformation.form.placeholders.text',
                       label,
                       labelHint,
                       hasError,
                       errorMessages,
                       onChange,
                   }: TextInputProps) => {
    const {t} = useTranslation()
    const placeholderText = t(placeholder)

    return (
        <>
            {hasHeader && <InputSpacer/>}
            <Input
                label={label}
                name={name}
                placeholder={placeholderText}
                type={type}
                labelHint={labelHint}
                hasError={hasError}
                errorMessages={errorMessages}
                value={value}
                onChange={onChange}
            />
        </>
    );
};

export default TextInput;
