import React, {useContext} from 'react';
import {Trans, useTranslation} from "react-i18next";
import {Button, Callout, Inline, Stack} from '@amboss/design-system';
import Heading from "./bits/Heading";
import useProfile from "../../../hooks/useProfile";
import {SocketContext} from "../../../contexts/SocketProvider";

declare type ProfileCompletedProps = {
    callback: () => void
    "data-testid"?: string
}

const ProfileCompleted = ({callback}: ProfileCompletedProps) => {
    const {t} = useTranslation()
    const {socketReady} = useContext(SocketContext)
    const {profile} = useProfile();
    const name = 'John Doe'

    return (
        <Stack space="m">
        <Inline vAlignItems={"center"} alignItems={"spaceBetween"}>
            {socketReady && profile &&
                <Heading>
                    <Trans i18nKey="accountSetup.sections.header.profileCompleted" 
                           values={{
                               name: `${profile.name} ${profile.lastName}`,
                           }}>
                        Welcome back
                        <strong>{{name} as any}</strong>.
                    </Trans>
                </Heading>
            }
            <Button variant='secondary' onClick={callback}>{t('accountSetup.profileCompleted.edit')}</Button>
        </Inline>
        <Callout  text={<b>{t('accountSetup.profileCompleted.calloutTextTop').toString()}</b>}
                  />
        <Callout  text={t('accountSetup.profileCompleted.calloutText').toString()}
                  description={t('accountSetup.profileCompleted.calloutDescription').toString()}
                  />
        </Stack>
    );
};

export default ProfileCompleted;
