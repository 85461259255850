import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useFormikContext} from "formik";
import { H6, Inline, Input } from "@amboss/design-system";
import {getOnChangeValue, getValueFromMinMax} from "../../utils";
import moment from "moment";

declare type DateInputProps = {
    title: string,
    formikField: string,
    defaultValue?: string
    showDay?: boolean
}
export default function DateInput({title, formikField, defaultValue, showDay}: DateInputProps) {
    const {t} = useTranslation()

    const {setFieldValue} = useFormikContext()

    const dayPlaceholder = t('accountSetup.sections.personalInformation.form.placeholders.dd')
    const monthPlaceholder = t('accountSetup.sections.personalInformation.form.placeholders.mm')
    const yearPlaceholder = t('accountSetup.sections.personalInformation.form.placeholders.yyyy')

    const dayLabel = t('accountSetup.sections.personalInformation.form.inputs.day')
    const monthLabel = t('accountSetup.sections.personalInformation.form.inputs.month')
    const yearLabel = t('accountSetup.sections.personalInformation.form.inputs.year')
    const [previousInput, setPreviousInput] = useState(defaultValue ? moment.utc(defaultValue) : moment())

    const defaultMoment = defaultValue ? moment.utc(defaultValue) : undefined
    const [day, setDay] = useState(showDay ? defaultMoment?.format('DD') || '' : '01');
    const [month, setMonth] = useState(defaultMoment?.format('MM') || '')
    const [year, setYear] = useState(defaultMoment?.format('YYYY') || '')

    useEffect(() => {
        if (day !== '' && month !== '' && year !== '') {
            setFieldValue(formikField, `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`).catch(e=>e)
        }
    }, [day, month, year])

    return (
        <>
            <H6 style={{textTransform: 'uppercase', color: '#314554', marginBottom: 4}}>{title}</H6>
            <Inline noWrap>
                <div style={{width: 90}}>
                    <Input
                        label={monthLabel}
                        name="month"
                        placeholder={monthPlaceholder}
                        type="text"
                        onChange={e => {
                            setMonth(getValueFromMinMax(0, 12, e.currentTarget.value).toString())
                        }}
                        onBlur={() => {
                            setMonth(getOnChangeValue(month, previousInput?.format('MM').toString().padStart(0, "2")))
                        }}
                        value={month}
                    />
                </div>
                {showDay &&
                    <div style={{width: 90}}>
                        <Input
                            label={dayLabel}
                            name="day"
                            placeholder={dayPlaceholder}
                            type="text"
                            onChange={e => {
                                setDay(getValueFromMinMax(0, 31, e.currentTarget.value).toString())
                            }}
                            onBlur={() => {
                                setDay(getOnChangeValue(day, previousInput?.format("DD")))
                            }}
                            value={day}
                        />
                    </div>
                }
                <div style={{width: 90}}>
                    <Input
                        label={yearLabel}
                        name="year"
                        placeholder={yearPlaceholder}
                        type="text"
                        onChange={e => {
                            setYear(getValueFromMinMax(0, moment().year(), e.currentTarget.value).toString())
                        }}
                        onBlur={() => {
                            setYear(getOnChangeValue(year, previousInput?.format("YYYY")))
                        }}
                        value={year}
                    />
                </div>
            </Inline>
        </>
    )
}
