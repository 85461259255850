import React, {useEffect, useState} from "react";
import {Badge, Checkbox, Divider, Inline, Stack, Callout} from "@amboss/design-system";
import ItemDate from "../../../atoms/ItemDate";
import {Credits} from "../../../../interfaces/credits";
import {CreditAssociation, CreditType} from "../../../../interfaces/credit";
import {useTranslation} from "react-i18next";
import TabList from "../tabs/TabList";
import useBottomModal from "../../../../hooks/useBottomModal";
import {useFormikContext} from "formik";
import moment from "moment/moment";
import {useAppSelector} from "../../../../hooks/redux";
import {creditsTypeSelector} from "../../../../store/credits/creditsSlice";
import IpcItemArticle from "../../../atoms/IpcItemArticle";
import ItemNejm from "../../../atoms/ItemNejm";
import {config} from "../../../../config";
import {createPortal} from "react-dom";
import EmptyPlaceholder from "../../../atoms/EmptyPlaceholder";
import useProfile from "../../../../hooks/useProfile";

declare type ReviewFormProps = {
    credits: Credits | undefined;
};

const ReviewedList = ({credits}: ReviewFormProps) => {
    const {t} = useTranslation();
    const {setBottomModalForm, creditIds, selectedCount} = useBottomModal();
    const {setFieldValue} = useFormikContext();
    const [creditItems, setCreditItems] = useState<any[]>([]);
    const [allChecked, setAllChecked] = useState<boolean>(false);
    const creditType = useAppSelector(creditsTypeSelector);
    const {profile} = useProfile()

    const calloutWrapperElement = document.getElementById('callout-portal')

    useEffect(() => {
        setFieldValue("creditIds", creditIds);
    }, [creditIds]);

    useEffect(() => {
        const items = credits?.items?.length 
        ? credits?.items.map((item) => {
            return {
                ...item,
                checked: false,
            };
        }) 
        : [{
            answered: 0,
            creditsCount: 0,
            remaining: 0,
            checked: false,
        }]
        setCreditItems(items || []);
    }, [credits]);

    const checkAllItems = () => {
        const items = creditItems?.map((item) => {
            item.checked = !allChecked;
            return item;
        });

        setAllChecked((prevState) => !prevState);
        setCreditItems(items!);
    };

    const updateCreditIds = (item: any, checked: boolean) => {
        if (creditType === CreditType.IPC) {
            return checked ? [...creditIds, item.id] : creditIds.filter((id: string) => id !== item.id);
        } else {
            return item.countedIds
        }
    }

    const updateCount = (itemValue: any, checked: boolean) => {
        return checked ? selectedCount + itemValue : selectedCount - itemValue;
    }

    const checkItemHandler = (item: any, checked: boolean) => {
        const value = creditType === CreditType.IPC ? item.value : item.creditsCount
        const updatedCount = updateCount(value, checked);
        const updatedCreditIds = updateCreditIds(item, checked)

        setBottomModalForm({
            selectedCount: updatedCount,
            creditIds: updatedCreditIds,
        });
    };

    const getCreditsSum = (credits: any) => {
        return credits.reduce((sum: number, {value}: { value: number }) => sum + value, 0);
    };

    useEffect(() => {
        if (allChecked) {
            const updatedCount = getCreditsSum(creditItems)
            const updatedCreditIds = creditItems.map(item => item.id)

            setBottomModalForm({
                selectedCount: updatedCount,
                creditIds: updatedCreditIds,
            });
        } else {
            setBottomModalForm({
                selectedCount: 0,
                creditIds: [],
            });
        }
    }, [allChecked])

    const selectAllName = t("credits.tabs.tab2.selectAll");

    const formattedDate = (item: any) => {
        return creditType === CreditType.IPC ? moment(item.updatedAt).format("MMMM D, YYYY") : `${moment(item.startDate).format("MMMM D, YYYY")} - ${moment(item.endDate).format("MMMM D, YYYY")}`
    }

    const getItemElement = (item: any) => {
        return creditType === CreditType.IPC ?
            <IpcItemArticle searchTerm={item.searchTerm} articleTitles={item.articleTitles}/> :
            <ItemNejm itemValue={item.answered}/>
    }

    const getCalloutText = (item: any) => {
        return t('credits.tabs.tab2.calloutText', {
            answersNeeded: config.answersNeeded,
            answer: item.answered,
            credit: item.creditsCount,
            question: config.answersNeeded - item.remaining
        })
    }

    const getCreditsValue = (item: any) => {
        return creditType === CreditType.IPC ? item.value : item.creditsCount
    }

    return (
        <>
            <Stack space={"m"}>
                {creditType === CreditType.IPC &&
                    <>
                        <Checkbox name={selectAllName} checked={allChecked} onChange={checkAllItems}
                                  label={selectAllName}/>
                        <Divider/>
                    </>}
                {creditItems?.map((item, i) => {
                    let creditValue = getCreditsValue(item)
                    return (
                        creditValue > 0 ?
                            <TabList
                                key={item.id || i+1}
                                left={
                                    <ItemDate
                                        date={formattedDate(item)}
                                        checked={item.checked}
                                        onCheck={(checked) => {
                                            item.checked = checked;
                                            checkItemHandler(item, checked);
                                        }}
                                    />
                                }
                                center={getItemElement(item)}
                                right={
                                    <Inline alignItems={"right"}>
                                        <Badge color={"brand"}
                                               text={t('credits.tabs.tab2.listItem.badge', {count: creditValue, type: (profile.association === CreditAssociation.AHPRA && creditType == CreditType.IPC) ? 'HOUR' : 'CREDIT'})}/>
                                    </Inline>
                                }
                                isLastItem={creditItems.length - 1 === i}
                            />
                            :
                            <EmptyPlaceholder key={item.id || i+1} header={t('credits.tabs.tab2.emptyPlaceholder.header')} subHeader=""/>
                    );
                })}
            </Stack>
            {creditType !== CreditType.IPC && calloutWrapperElement && creditItems.length > 0 && createPortal(<Callout
                text={getCalloutText(creditItems[0])}/>, calloutWrapperElement)}
        </>
    );
};

export default ReviewedList;
