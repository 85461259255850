import React from "react";
import {H4, Inline, Stack, Text} from "@amboss/design-system";
import {useTranslation} from "react-i18next";
import SpecialtyStateForm from "../specialtyState/SpecialtyStateForm";
import {config} from "../../../../config";

const SpecialtyState = () => {
    const {t} = useTranslation();

    const userCodeLabelHint = t("accountSetup.sections.specialtyState.hint");
    const userCodeLabelInstructions = t("accountSetup.sections.specialtyState.instructions");

    return (
        <Stack space="m">
            <Stack space="xxs">
                <Inline vAlignItems={"center"}>
                    <H4>{t("accountSetup.sections.specialtyState.title")}</H4>
                    <Text size={"xs"} color={"tertiary"}>
                        {userCodeLabelHint}
                    </Text>
                </Inline>
                <Text size={"s"} color={"tertiary"}>
                    {userCodeLabelInstructions}
                </Text>
            </Stack>
            <Stack space={"xxl"}>
                {Object.values(config.forms.specialtyState.types).map((type:any) => {
                    return <SpecialtyStateForm type={type} key={type}/>
                })}
            </Stack>
        </Stack>
    );
};

export default SpecialtyState;
