import React from 'react';
import {Button, Inline, Link, Stack} from "@amboss/design-system";
import {useTranslation} from "react-i18next";
import {config} from "../../../../config";

const Footer = () => {
    const {t} = useTranslation()

    return (
        <Stack space={"l"}>
            <Link href={config.external.links.OTHER_TYPES}
                  target={"_blank"} rel={"noopener noreferrer"}>{t('accountSetup.sections.footer.link')}</Link>
            <Inline space={"l"} alignItems={'right'}>
            <Button type={'reset'} variant='secondary'>
                    {t('accountSetup.sections.footer.cancel')}
                </Button>
                <Button type={'submit'}>
                    {t('accountSetup.sections.footer.button')}
                </Button>
            </Inline>
        </Stack>
    );
};

export default Footer;
