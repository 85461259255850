import styles from './Navbar.module.css';
import {Button, Inline} from '@amboss/design-system';
import {useTranslation} from "react-i18next";
import logo from '../../assets/images/logo.svg'
import {config} from "../../config";

const Navbar = () => {
    const {t} = useTranslation()

    return (
        <div className={styles.wrapper}>
            <div className={styles.innerWrapper}>
                <Inline alignItems="spaceBetween" vAlignItems="center">
                    <a href={config.external.links.LOGOUT}>
                        <img src={logo} alt="logo"/>
                    </a>
                    <Inline vAlignItems="center" alignItems="spaceBetween">
                        <Button variant="tertiary"
                                onClick={() => window.location.replace(config.external.links.LOGOUT)}>
                            {t('navbar.exit')}
                        </Button>
                        <Button variant="tertiary"
                                onClick={() => window.open("https://support.amboss.com/hc/en-us/categories/360004601531-AMBOSS-for-Clinicians", "blank")}>
                            {t('navbar.help')}
                        </Button>
                    </Inline>
                </Inline>
            </div>
        </div>
    );
};

export default Navbar;
