import React from 'react';
import {Column, Columns, Divider, Stack} from "@amboss/design-system";
import type {ColumnSizes, MQ} from "@amboss/design-system/build/cjs/types";

declare type TabListProps = {
    left: React.ReactNode,
    center: React.ReactNode,
    right: React.ReactNode,
    isLastItem: boolean,
    leftSize?: ColumnSizes | MQ<ColumnSizes>
    centerSize?: ColumnSizes | MQ<ColumnSizes>
    rightSize?: ColumnSizes | MQ<ColumnSizes>
}

const TabList = ({left, center, right, leftSize = [12, 4, 3], centerSize = [12, 5, 6], rightSize = [12, 3, 3], isLastItem}: TabListProps) => {
    return (
        <Stack space={"m"}>
            <Columns gap={'m'} vAlignItems={"center"}>
                <Column size={leftSize}>
                    {left}
                </Column>
                <Column size={centerSize}>
                    {center}
                </Column>
                <Column size={rightSize}>
                    {right}
                </Column>
            </Columns>
            {!isLastItem && <div style={{marginBottom: 16}}><Divider/></div>}
        </Stack>

    );
};

export default TabList;
