import {Button, Inline, Stack, Text} from "@amboss/design-system";
import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";

const ErrorBoundary = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const goToHome = () => {
        navigate({pathname: '/', search: `?${createSearchParams(searchParams)}`})
    }
    const goToHelpPage = () => {
        window.open("https://support.amboss.com/hc/en-us/categories/360004601531-AMBOSS-for-Clinicians","blank");
    }
    return (
        <div style={{
            display: 'flex',
            maxWidth: 328,
            margin: "auto",
            marginTop: (window.innerHeight / 2) - 92
        }}>
            <Stack alignItems={'center'}>
                <Text weight={"bold"} align={"center"}>Page not found</Text>
                <Text size={"s"} align="center" color={"tertiary"}>
                    The page you were looking for does not exist.
                    Please go back to the Homepage
                </Text>
                <Inline vAlignItems={'center'} alignItems={"spaceBetween"}>
                <Button
                    onClick={goToHome}
                    size="s"
                    color="primary"
                >
                    Go Back
                </Button>
                <Button
                    onClick={goToHelpPage}
                    size="s"
                    color="primary"
                >
                    Help
                </Button>
                </Inline>
            </Stack>
        </div>
    );
}
export default ErrorBoundary;
